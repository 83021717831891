import React from 'react';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	message
} from 'antd';
import {
	HomeOutlined,
	TeamOutlined,
	HeartOutlined,
	CarOutlined,
	SnippetsOutlined
} from '@ant-design/icons';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";

import Config from '../Config';

import { Helmet } from "react-helmet";

import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
	chartOptions,
	parseOptions,
	chartExample1,
	chartExample2
} from "../vars/chart.jsx";

const mode = "light";
const fonts = {
	base: "Open Sans"
};

const configClass = {
	title: "DASHBOARD",
	apiUrl: {
		dashboard: "dashboard/"
	}
}

const config = new Config();

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			homecare: 0,
			emergency: 0,
			ambulan: 0,
			konsultasi: 0,
			grafikPerbulan: {
				labels: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Ags", "Sep", "Okt", "Nov", "Des"],
				datasets: [
					{
						label: "Rujukan Perbulan",
						data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
					}
				]
			},
			grafikPerhari: {
				labels: ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"],
				datasets: [
					{
						label: "Rujukan Perhari",
						data: [0, 0, 0, 0, 0, 0, 0]
					}
				]
			},
		}
	}

	UNSAFE_componentWillMount() {
		this.props.initHandler();
		if (window.Chart) {
			parseOptions(Chart, chartOptions());
		}
	}

	componentDidMount() {
		// this.getData();
	}

	async getData() {
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);
		message.loading({ content: 'Menampilkan data dashboard...', key: 'toast' });
		this.props.ajaxViewHandler('get', configClass.apiUrl.dashboard)
			.then(() => {
				const data = this.props.responseMsg;
				if (this.props.isSuccess) {
					message.destroy();
					// console.log('bln_januari', data.bulan[0]);
					this.setState({
						homecare: data.homecare,
						emergency: data.emergency,
						ambulan: data.ambulan,
						konsultasi: data.konsultasi,
						grafikPerbulan: {
							labels: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Ags", "Sep", "Okt", "Nov", "Des"],
							datasets: [
								{
									label: "Rujukan Perbulan",
									data: [
										data.bulan.Januari,
										data.bulan.Febuari,
										data.bulan.Maret,
										data.bulan.April,
										data.bulan.Mei,
										data.bulan.Juni,
										data.bulan.Juli,
										data.bulan.Agustus,
										data.bulan.September,
										data.bulan.Oktober,
										data.bulan.November,
										data.bulan.Desember
									]
								}
							]
						},
						grafikPerhari: {
							labels: ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"],
							datasets: [
								{
									label: "Rujukan Perhari",
									data: [
										data.hari.Senin,
										data.hari.Selasa,
										data.hari.Rabu,
										data.hari.Kamis,
										data.hari['Jum`at'],
										data.hari.Sabtu,
										data.hari.Minggu
									]
								}
							]
						}
					});
				} else {
					const errTitle = 'error get data dashboard';
					this.setState({
						errTitle: errTitle,
						errMsg: this.props.responseMsg
					});
					message.error({ content: errTitle, key: 'toast' });
				}
			}).catch((response) => {
				console.log('gagal_get_dashboard', response);
				message.error({ content: 'gagal request data, coba lagi', key: 'toast' });
				this.setState({ loadingData: false });
			});
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{configClass.title + " | " + config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					<Breadcrumb.Item>
						<HomeOutlined /> Home
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						Dashboard
					</Breadcrumb.Item>
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col lg={6} md={24} sm={24} xs={24} style={{ padding: 10 }}>

							<Card
								style={{}}
								bordered={false}
								bodyStyle={{
									textAlign: 'center',
									fontSize: 26,
									fontWeight: 'bold',
									padding: 5,
								}}
							>
								<Row>
									<Col
										span="10"
										className="be-dashboard-info"
									>
										<Avatar
											size={70}
											style={{
												background: '#11CDEF'
											}}
											className="be-dashboard-round"
										>
											<TeamOutlined style={{ fontSize: 40 }} />
										</Avatar>
									</Col>
									<Col span="14" style={{ textAlign: 'left' }}>
										<span className="label-dashboard">
											Label 1<br />
										</span>
										<span className="counter-dashboard">
											{this.state.homecare}
										</span>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col lg={6} md={24} sm={24} xs={24} style={{ padding: 10 }}>

							<Card
								style={{}}
								bordered={false}
								bodyStyle={{
									textAlign: 'center',
									fontSize: 26,
									fontWeight: 'bold',
									padding: 5,
								}}
							>
								<Row>
									<Col
										span="10"
										className="be-dashboard-info"
									>
										<Avatar
											size={70}
											style={{
												background: '#F64B6D'
											}}
											className="be-dashboard-round"
										>
											<HeartOutlined style={{ fontSize: 40 }} />
										</Avatar>
									</Col>
									<Col span="14" style={{ textAlign: 'left' }}>
										<span className="label-dashboard">
											Label 2<br />
										</span>
										<span className="counter-dashboard">
											{this.state.emergency}
										</span>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col lg={6} md={24} sm={24} xs={24} style={{ padding: 10 }}>

							<Card
								style={{}}
								bordered={false}
								bodyStyle={{
									textAlign: 'center',
									fontSize: 26,
									fontWeight: 'bold',
									padding: 5,
								}}
							>
								<Row>
									<Col
										span="10"
										className="be-dashboard-info"
									>
										<Avatar
											className="be-dashboard-round"
											size={70}
											style={{
												background: '#43D395'
											}}
										>
											<CarOutlined style={{ fontSize: 40 }} />
										</Avatar>
									</Col>
									<Col span="14" style={{ textAlign: 'left' }}>
										<span className="label-dashboard">
											Label 3<br />
										</span>
										<span className="counter-dashboard">
											{this.state.ambulan}
										</span>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col lg={6} md={24} sm={24} xs={24} style={{ padding: 10 }}>

							<Card
								style={{}}
								bordered={false}
								bodyStyle={{
									textAlign: 'center',
									fontSize: 26,
									fontWeight: 'bold',
									padding: 5,
								}}
							>
								<Row>
									<Col
										span="10"
										className="be-dashboard-info"
									>
										<Avatar
											size={70}
											style={{
												background: '#FFE03D'
											}}
											className="be-dashboard-round"
										>
											<SnippetsOutlined style={{ fontSize: 40 }} />
										</Avatar>
									</Col>
									<Col span="14" style={{ textAlign: 'left' }}>
										<span className="label-dashboard">
											Label 4<br />
										</span>
										<span className="counter-dashboard">
											{this.state.konsultasi}
										</span>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col sm={24} lg={12} style={{ padding: 10 }}>
							<Card
								className="grafik-perbulan"
								title="Grafik Perbulan"
							>
								<Line
									data={this.state.grafikPerbulan}
									options={chartExample1.options}
								/>
							</Card>
						</Col>
						<Col sm={24} lg={12} style={{ padding: 10 }}>
							<Card
								title="Grafik Perhari"
							>
								<Bar
									data={this.state.grafikPerhari}
									options={chartExample2.options}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function (state) {
	return state.configReducers
}

export default connect(
	mapStateToProps,
	{
		initHandler,
		setConfig,
		ajaxViewHandler,
		ajaxHandler
	}
)(Home);