import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Menu,
  Avatar,
  Space,
  BackTop,
  Drawer,
  Input
} from 'antd';
import {
  ClusterOutlined,
  DashboardOutlined,
  HomeOutlined,
  HeartOutlined,
  TeamOutlined,
  SnippetsOutlined,
  MailOutlined,
  GroupOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  GlobalOutlined,
  DropboxOutlined,
  BugOutlined,
  LoginOutlined,
  SettingOutlined,
  BankOutlined,
  FolderOpenOutlined,
  SmileOutlined,
  DatabaseOutlined,
  ToolOutlined,
  LayoutOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  DiffOutlined,
  SwapOutlined,
  ShoppingCartOutlined,
  ArrowsAltOutlined,
  CarOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  CopyOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { setConfig, ajaxViewHandler } from "../store/actions";

import Config from '../Config';

const { SubMenu } = Menu;
const { Sider } = Layout;

const configClass = {
  apiUrl: {
    sider: ""
  }
}

class AdminSider extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      cariMenu: "",
      activeMenu: [],
    }
  }

  componentDidMount() {
    //this.getInfoSidebar();
  }

  setAllActive() {
    this.setState({
      activeMenu: ["menumaster", "mn_pegawai", "mn_pengaturan", "mn_info", "mn_transaksi", "mn_laporan"],
    })
  }

  cariMenu(keyword, source) {
    const temps = source.split(" ");
    const temps2 = keyword.split(" ");
    let result = false;
    for (let i = 0; i < temps.length; i++) {
      for (let x = 0; x < temps2.length; x++) {
        if (temps[i].toLowerCase().includes(temps2[x].toLowerCase())) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  onMenuExpand = (listOpen) => {
    if (listOpen.length == 1) {
      // console.log("kode1", listOpen);
      this.setState({
        // activeMenu: listOpen[0],
        activeMenu: listOpen,
      });
    } else {
      // console.log("kode2", listOpen[listOpen.length - 1]);
      this.setState({
        // activeMenu: listOpen[listOpen.length - 1],
        activeMenu: listOpen,
      });
    }
    // if (selectionToolIsDisable) {
    //   const openedKeyIndex = openKeys.indexOf('menu1')
    //     if (openedKeyIndex !== -1) {
    //         const _openKeys = [...openKeys]
    //         _openKeys.splice(openedKeyIndex, 1)
    //         setOpenKeys(_openKeys)
    //     }
    // } else {
    //     setOpenKeys([...openKeys, 'menu1'])
    // }
    // this.setState({
    //   defaultOpenKeys: ["mn_task"]
    // })
  };


  notifyMe(msg) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(msg);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(msg);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  async getInfoSidebar() {
    this.props.ajaxViewHandler('get', configClass.apiUrl.sider)
      .then(() => {
        const data = this.props.responseMsg.results[0];
        if (this.props.isSuccess) {
          /*
          this.setState({
            logoDesa: data.logo!=null&&data.logo!=''?data.logo:process.env.PUBLIC_URL + "/logo_landscape.png",
            namaDesa: data.name,
          });
          */
        } else {
          /*
          this.setState({
            namaDesa: 'gagal menampilkan info desa',
          });
          */
        }
      }).catch((response) => {
        /*
        this.setState({
            namaDesa: 'gagal menampilkan info desa',
        });
        */
      });
  }

  render() {
    return (
      <PerfectScrollbar>
        <Helmet>
          <title>{"admin | " + (this.props.nama != undefined ? this.props.nama : this.config.get_site_info().name)}</title>
        </Helmet>
        <Sider
          breakpoint="lg"
          width={this.props.isMobile ? '100%' : 256}

          trigger={null}
          collapsible
          collapsed={this.props.collapsed}
          className="sider"
        /*
        onCollapse={(collapsed, type) => {
          this.props.setConfig({
            collapsed: collapsed
          });
        }}
        */
        /*
        onBreakpoint={
          console.log('break')
          //!this.props.isMobile && this.props.setConfig({collapsed:!this.props.collapsed})
        }
        */
        >
          <div className="brand">
            <div className="logo">
              {this.props.collapsed == false
                ?
                <img alt="logo" src={this.config.get_site_info().logo_landscape} />
                :
                <img alt="logo" src={this.config.get_site_info().logo} />
              }
              {/*{!this.props.collapsed && <h1>Abdi Desa</h1>}*/}
            </div>
          </div>
          {/* <div style={{ paddingLeft: 20, paddingRight: 10 }}> */}
          <Input
            allowClear
            placeholder="Cari Menu"
            style={{
              borderLeft: 0,
              borderRight: 0,
              width: "100%",
              paddingLeft: 30,
              paddingRight: 20,
              textTransform: "none"
            }}
            onChange={(e) => {
              this.setState({
                cariMenu: e.target.value
              }, () => {
                console.log(this.state.cariMenu)
                if (this.state.cariMenu == "") {
                  this.setState({
                    activeMenu: []
                  })
                } else {
                  this.setAllActive();
                }
              })
            }}
          />
          {/* </div> */}
          <Menu
            mode="inline"
            className="sidebar"
            openKeys={this.state.activeMenu}
            defaultOpenKeys={this.state.activeMenu}
            onOpenChange={this.onMenuExpand}
          >
            <Menu.Item key="dashboard">
              <DashboardOutlined style={{ marginRight: 10 }} />
              <Link to="/admin/index">
                {(!this.props.collapsed && 'Dashboard')}
              </Link>
            </Menu.Item>
            {(this.props.akses == null ||
              (this.props.akses != undefined && this.props.akses.master == true &&
                (this.state.cariMenu == "" ||
                  this.cariMenu(this.state.cariMenu, "master tanda tangan negara Merek tipe jenis kendaraan bahan bakar warna pekerjaan kepemilikan Layanan Dealer Payment Golongan Warna Plat Fungsi"))
              )
              &&
              <SubMenu
                key="menumaster"
                title={
                  <span>
                    <DiffOutlined />
                    <span>Master</span>
                  </span>
                }
              >
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_tanda_tangan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "tanda tangan")) &&
                      <Menu.Item key="tanda_tangan">
                        <Link to="/admin/tanda_tangan">
                          Tanda Tangan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_negara == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "negara")) &&
                      <Menu.Item key="negara">
                        <Link to="/admin/negara">
                          Negara
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_merek == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "merek")) &&
                      <Menu.Item key="merek">
                        <Link to="/admin/merek">
                          Merek
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_jenis_kendaraan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "jenis kendaraan")) &&
                      <Menu.Item key="jenis_kendaraan">
                        <Link to="/admin/jenis-kendaraan">
                          Jenis Kendaraan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_Tipe == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "tipe")) &&
                      <Menu.Item key="tipe">
                        <Link to="/admin/tipe">
                          Tipe
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_bahan_bakar == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "bahan bakar")) &&
                      <Menu.Item key="bahan_bakar">
                        <Link to="/admin/bahan-bakar">
                          Bahan Bakar
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_warna == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "warna")) &&
                      <Menu.Item key="warna">
                        <Link to="/admin/warna">
                          Warna
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_pekerjaan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "pekerjaan")) &&
                      <Menu.Item key="pekerjaan">
                        <Link to="/admin/pekerjaan">
                          Pekerjaan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_kepemilikan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "kepemilikan")) &&
                      <Menu.Item key="kepemilikan">
                        <Link to="/admin/kepemilikan">
                          Kepemilikan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_layanan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Layanan")) &&
                      <Menu.Item key="layanan">
                        <Link to="/admin/layanan">
                          Layanan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_dealer == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Dealer")) &&
                      <Menu.Item key="dealer">
                        <Link to="/admin/dealer">
                          Dealer
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_payment == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Payment")) &&
                      <Menu.Item key="payment">
                        <Link to="/admin/payment">
                          Payment
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_golongan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Golongan")) &&
                      <Menu.Item key="golongan">
                        <Link to="/admin/golongan">
                          Golongan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_warna_plat == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Warna Plat")) &&
                      <Menu.Item key="warna_plat">
                        <Link to="/admin/warna-plat">
                          Warna Plat
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.master_fungsi == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Fungsi")) &&
                      <Menu.Item key="fungsi">
                        <Link to="/admin/fungsi">
                          Fungsi
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
              </SubMenu>
            )}

            {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pegawai == true &&
              (this.state.cariMenu == "" ||
                this.cariMenu(this.state.cariMenu, "Jabatan Waktu Operasional Upt Bidang Pegawai")
              )
            ) &&
              <SubMenu
                key="mn_pegawai"
                title={
                  <span>
                    <UserOutlined />
                    <span>Pegawai</span>
                  </span>
                }
              >
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pegawai_jabatan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Jabatan")) &&
                      <Menu.Item key="jabatan">
                        <Link to="/admin/jabatan">
                          Jabatan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.waktu_operasional == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Waktu Operasional")) &&
                      <Menu.Item key="waktu_operasional">
                        <Link to="/admin/waktu-operasional">
                          Waktu Operasional
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pegawai_upt == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Upt")) &&
                      <Menu.Item key="upt">
                        <Link to="/admin/upt">
                          Upt
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pegawai_bidang == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Bidang")) &&
                      <Menu.Item key="bidang">
                        <Link to="/admin/bidang">
                          Bidang
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pegawai_pegawai == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Pegawai")) &&
                      <Menu.Item key="pegawai">
                        <Link to="/admin/pegawai">
                          Pegawai
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

              </SubMenu>
            )}
            {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan == true &&
              (this.state.cariMenu == "" ||
                this.cariMenu(this.state.cariMenu, "Pengaturan Diskon Umum NJKB Import NJKB SWDKLLJ Adm. STNK Adm. TNKB Denda Pkb Denda SWDKLLJ Notice Tanda Tangan Laporan Printer")
              )
            ) &&
              <SubMenu
                key="mn_pengaturan"
                title={
                  <span>
                    <SettingOutlined />
                    <span>Pengaturan</span>
                  </span>
                }
              >
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_dikon == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Diskon")) &&
                      <Menu.Item key="diskon">
                        <Link to="/admin/pengaturan/diskon">
                          Diskon
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_umum == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Umum")) &&
                      <Menu.Item key="umum">
                        <Link to="/admin/pengaturan/umum">
                          Umum
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_njkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "NJKB")) &&
                      <Menu.Item key="njkb">
                        <Link to="/admin/pengaturan/njkb">
                          NJKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_njkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Import NJKB")) &&
                      <Menu.Item key="njkb-import">
                        <Link to="/admin/pengaturan/njkb-import">
                          Import NJKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_swdkllj == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "SWDKLLJ")) &&
                      <Menu.Item key="swdkllj">
                        <Link to="/admin/pengaturan/swdkllj">
                          SWDKLLJ
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_adm_stnk == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Adm. STNK")) &&
                      <Menu.Item key="admstnk">
                        <Link to="/admin/pengaturan/admstnk">
                          Adm. STNK
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_adm_tnkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Adm. TNKB")) &&
                      <Menu.Item key="admtnkb">
                        <Link to="/admin/pengaturan/admtnkb">
                          Adm. TNKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_denda_pkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Denda Pkb")) &&
                      <Menu.Item key="denda_pkb">
                        <Link to="/admin/pengaturan/denda-pkb">
                          Denda Pkb
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_denda_swdkllj == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Denda SWDKLLJ")) &&
                      <Menu.Item key="denda_swdkllj">
                        <Link to="/admin/pengaturan/denda-swdkllj">
                          Denda SWDKLLJ
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_notice == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Notice")) &&
                      <Menu.Item key="pengaturan_notice">
                        <Link to="/admin/pengaturan/notice">
                          Notice
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_tanda_tangan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Tanda Tangan Laporan")) &&
                      <Menu.Item key="pengaturan_ttd">
                        <Link to="/admin/pengaturan/tanda_tangan_laporan">
                          Tanda Tangan Laporan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.pengaturan_printer == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Printer")) &&
                      <Menu.Item key="pengaturan_printer">
                        <Link to="/admin/pengaturan/printer">
                          Printer
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

              </SubMenu>
            )}
            {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.informasi == true &&
              (this.state.cariMenu == "" ||
                this.cariMenu(this.state.cariMenu, "Informasi Kendaraan Cari Data KBM Migrasi")
              )
            ) &&
              <SubMenu
                key="mn_info"
                title={
                  <span>
                    <InfoCircleOutlined />
                    <span>Informasi</span>
                  </span>
                }
              >
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.informasi_informasi_kendaraan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Informasi Kendaraan")) &&
                      <Menu.Item key="informasi_kendaraan">
                        <Link to="/admin/informasi/informasi-kendaraan">
                          Informasi Kendaraan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {/* {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.informasi_cari_data_kbm == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Cari Data KBM")) &&
                      <Menu.Item key="cari_data_kbm">
                        <Link to="/admin/informasi/cari-data-kbm">
                          Cari Data KBM
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )} */}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.informasi_migrasi == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Migrasi")) &&
                      <Menu.Item key="migrasi">
                        <Link to="/admin/informasi/migrasi">
                          Migrasi
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
              </SubMenu>
            )}
            {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi == true &&
              (this.state.cariMenu == "" ||
                this.cariMenu(this.state.cariMenu, "Pendaftaran Penetapan Pembayaran Surat Keterangan Fiskal Cancel Notice Jasaraharja IWKBU blokir")
              )
            ) &&
              <SubMenu
                key="mn_transaksi"
                title={
                  <span>
                    <CarOutlined />
                    <span>Transaksi</span>
                  </span>
                }
              >
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.blokir == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Blokir")) &&
                      <Menu.Item key="blokir">
                        <Link to="/admin/transaksi/blokir">
                          Blokir
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_pendaftaran == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Pendaftaran")) &&
                      <Menu.Item key="pdftrn">
                        <Link to="/admin/transaksi/pendaftaran">
                          Pendaftaran
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_penetapan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Penetapan")) &&
                      <Menu.Item key="jarak_tanam">
                        <Link to="/admin/transaksi/penetapan-biaya">
                          Penetapan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_pembayaran == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Pembayaran")) &&
                      <Menu.Item key="pembayaran">
                        <Link to="/admin/transaksi/pembayaran">
                          Pembayaran
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_surat_keterangan_fiskal == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Surat Keterangan Fiskal")) &&
                      <Menu.Item key="surat_fiskal">
                        <Link to="/admin/transaksi/surat-keterangan-fiskal">
                          Surat Keterangan Fiskal
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_cancle_notice == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Cancel Notice")) &&
                      <Menu.Item key="cancel_notice">
                        <Link to="/admin/transaksi/cancel-notice">
                          Cancel Notice
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.transaksi_jasa_raharja == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "Jasaraharja")) &&
                      <Menu.Item key="jasaraharja">
                        <Link to="/admin/transaksi/jasaraharja">
                          Jasaraharja
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.iwkbu == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "IWKBU")) &&
                      <Menu.Item key="iwkbu">
                        <Link to="/admin/transaksi/iwkbu">
                          IWKBU
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

              </SubMenu>
            )}

            {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan == true &&
              (this.state.cariMenu == "" ||
                this.cariMenu(this.state.cariMenu, "laporan PNBP Kasir Perekening Pemutihan Cetak STNK Bendahara Penerimaan Bendahara PKB &amp; BBNKB Penetapan PKB &amp; BBNKB Penerimaan PKB Warna Plat STSS Harian SWDKLLJ Pengantar Penyetoran Uang Penerimaan BBNKB &amp; PKB Notice Notice Rusak")
              )
            ) &&
              <SubMenu
                key="mn_laporan"
                title={
                  <span>
                    <CopyOutlined />
                    <span>Laporan</span>
                  </span>
                }
              >

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_pnbp == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan PNBP")) &&
                      <Menu.Item key="lap_pnbp">
                        <Link to="/admin/laporan/pnbp">
                          PNBP
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_kasir == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Kasir")) &&
                      <Menu.Item key="lap_kasir">
                        <Link to="/admin/laporan/kasir">
                          Kasir
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_perekening == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Perekening")) &&
                      <Menu.Item key="lap_perekening">
                        <Link to="/admin/laporan/perekening">
                          Perekening
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_pemutihan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Pemutihan")) &&
                      <Menu.Item key="lap_pemutihan">
                        <Link to="/admin/laporan/pemutihan">
                          Pemutihan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_cetak_stnk == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Cetak STNK")) &&
                      <Menu.Item key="lap_cetak_stnk">
                        <Link to="/admin/laporan/cetak-stnk">
                          Cetak STNK
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_bendahara_penerimaan == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Bendahara Penerimaan")) &&
                      <Menu.Item key="lap_bendahara_pnerimaan">
                        <Link to="/admin/laporan/bendahara-penerimaan">
                          Bendahara Penerimaan
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_bendaraha_pkb_bbnkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Bendahara PKB &amp; BBNKB")) &&
                      <Menu.Item key="lap_bendahara_pkb">
                        <Link to="/admin/laporan/bendahara-pkb">
                          Bendahara PKB &amp; BBNKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_penetapan_pkb_bbnkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Penetapan PKB &amp; BBNKB")) &&
                      <Menu.Item key="lap_harian_pkb">
                        <Link to="/admin/laporan/harian-pkb">
                          Penetapan PKB &amp; BBNKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_penerimaan_pkb_plat == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Penerimaan PKB Warna Plat")) &&
                      <Menu.Item key="lap_pkb_warna">
                        <Link to="/admin/laporan/penerimaan-pkb-warna">
                          Penerimaan PKB Warna Plat
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_sts == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan STSS")) &&
                      <Menu.Item key="lap_stss">
                        <Link to="/admin/laporan/stss">
                          STSS
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_sts == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan STS Harian")) &&
                      <Menu.Item key="lap_stss_harian">
                        <Link to="/admin/laporan/stss-harian">
                          STS Harian
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_swdkllj == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan SWDKLLJ")) &&
                      <Menu.Item key="lap_swdkllj">
                        <Link to="/admin/laporan/swdkllj">
                          SWDKLLJ
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.penyetoran_uang == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Pengantar Penyetoran Uang")) &&
                      <Menu.Item key="lap_penyet_uang">
                        <Link to="/admin/laporan/penyetoran-uang">
                          Pengantar Penyetoran Uang
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}

                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_penerimaan_bbnkb_pkb == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Penerimaan BBNKB &amp; PKB")) &&
                      <Menu.Item key="lap_bbnkb">
                        <Link to="/admin/laporan/bbnkb-pkb">
                          Penerimaan BBNKB &amp; PKB
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_notice == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Notice")) &&
                      <Menu.Item key="lap_notice">
                        <Link to="/admin/laporan/notice">
                          Notice
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
                {(this.props.akses == null || (this.props.akses != undefined && this.props.akses.laporan_notice_rusak == true) &&
                  <>
                    {(this.state.cariMenu == "" || this.cariMenu(this.state.cariMenu, "laporan Notice Rusak")) &&
                      <Menu.Item key="lap_notice_rusak">
                        <Link to="/admin/laporan/notice-rusak">
                          Notice Rusak
                        </Link>
                      </Menu.Item>
                    }
                  </>
                )}
              </SubMenu>
            )}
          </Menu>
        </Sider>
      </PerfectScrollbar>
    )
  }
}

const mapStateToProps = function (state) {
  return state.configReducers
}

export default connect(mapStateToProps, { setConfig, ajaxViewHandler })(AdminSider);
