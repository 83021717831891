import React from 'react';
import { Layout } from 'antd';
import Config from '../Config';

const { Footer } = Layout;

class AdminFooter extends React.Component {
	config;

	constructor(props){
	    super(props);
	    this.config = new Config();
	}

	render() {
	    return (
			<Footer>
				&copy; 2024 {this.config.get_site_info().name}
			</Footer>
		)
	}
}

export default AdminFooter;