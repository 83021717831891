import React, { Component } from 'react';

class Config {
	/*
	jenis layanan: 1 home care, ambulance 2, laboratorium 3, obat 4
	*/
	get_site_info() {
		return {
			name: 'E-SAMSAT KALTENG',
			logo: process.env.PUBLIC_URL + "/symbol.png",
			logo_landscape: process.env.PUBLIC_URL + "/logo-samsat-kalteng.png",
			logo_bw: process.env.PUBLIC_URL + "/logo_bw.jpg",
			description: 'E-Samsat Kalimantan Tengah'
		}
	}

	get_server_url() {
		return process.env.REACT_APP_API_PATH;
	}

	get_base_url() {
		return process.env.REACT_APP_API_PATH;
	}

	get_web_socket() {
		return '';
	}

	get_cookie_name() {
		return 'rs4m5t_cr4uth';
	}

	stripTags(teks) {
		return teks.replace(/(<([^>]+)>)/ig, "");//strip tags html
	}

	to_idr(value) {
		return parseFloat(value).toLocaleString('en-us');
	}

	toSpace(teks) {
		return teks.replace(/_/ig, " ");//strip tags html
	}

	escape_str(value) {
		return (
			<div>
				{value.includes("https://") || value.includes("http://") || value.includes("www.")
					?
					<div>
						<a href={value} target="_blank">{value}</a>
					</div>
					:
					value
				}
			</div>
		);
	}

	cm_to_pixel(cm) {
		return cm * 37.7952755906;
	}

	pembilang(nilai) {
		nilai = Math.floor(Math.abs(nilai));

		var simpanNilaiBagi = 0;
		var huruf = [
			'',
			'Satu',
			'Dua',
			'Tiga',
			'Empat',
			'Lima',
			'Enam',
			'Tujuh',
			'Delapan',
			'Sembilan',
			'Sepuluh',
			'Sebelas',
		];
		var temp = '';

		if (nilai < 12) {
			temp = ' ' + huruf[nilai];
		} else if (nilai < 20) {
			temp = this.pembilang(Math.floor(nilai - 10)) + ' Belas';
		} else if (nilai < 100) {
			simpanNilaiBagi = Math.floor(nilai / 10);
			temp = this.pembilang(simpanNilaiBagi) + ' Puluh' + this.pembilang(nilai % 10);
		} else if (nilai < 200) {
			temp = ' Seratus' + this.pembilang(nilai - 100);
		} else if (nilai < 1000) {
			simpanNilaiBagi = Math.floor(nilai / 100);
			temp = this.pembilang(simpanNilaiBagi) + ' Ratus' + this.pembilang(nilai % 100);
		} else if (nilai < 2000) {
			temp = ' Seribu' + this.pembilang(nilai - 1000);
		} else if (nilai < 1000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000);
			temp = this.pembilang(simpanNilaiBagi) + ' Ribu' + this.pembilang(nilai % 1000);
		} else if (nilai < 1000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000);
			temp = this.pembilang(simpanNilaiBagi) + ' Juta' + this.pembilang(nilai % 1000000);
		} else if (nilai < 1000000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000000);
			temp =
				this.pembilang(simpanNilaiBagi) + ' Miliar' + this.pembilang(nilai % 1000000000);
		} else if (nilai < 1000000000000000) {
			simpanNilaiBagi = Math.floor(nilai / 1000000000000);
			temp = this.pembilang(nilai / 1000000000000) + ' Triliun' + this.pembilang(nilai % 1000000000000);
		}

		return temp;
	}
}
export default Config
